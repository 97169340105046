import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { HomePage } from "./Home.js";
import { About } from "./About.js";
import { HeaderComponent } from "./Header.js";
import { FooterComponent } from "./Footer.js";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Services } from "./Services";
import { NewPatient } from "./New";
import { Photos } from "./Photos";
import { Acupuncture } from "./Acupuncture";
// import { CovidModal } from "./CovidModal";
// import { CovidResponse } from "./CovidResponse";

const App = () => {
  return (
    <div className="app-container">
      <Router>
        <HeaderComponent />
        {/* <CovidModal /> */}
        <div className="body-container" style={{ paddingTop: 70 }}>
          <Route exact path="/" component={HomePage} />
          {/* <Route exact path="/response" component={CovidResponse} /> */}
          <Route exact path="/about" component={About} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/photos" component={Photos} />
          <Route exact path="/new" component={NewPatient} />
          <Route exact path="/Acupuncture" component={Acupuncture} />
        </div>
        <FooterComponent />
      </Router>
    </div>
  );
};

export default App;
