import React, { Fragment } from "react";
import Card from "react-bootstrap/Card.js";
import selfie from "./Images/selfie3.jpg";
import { ReadMore } from "./Components/ReadMore";

export const HomePage = () => {
  const reasonsForTreatment = [
    "You're seeking the ultimate treatment for pain management.",
    "You're seeking fertility treatment for your future children.",
    "You're seeking inner peace.",
  ];

  const ServicesComponent = ({
    title,
    description,
    imageUrl,
    borderColor,
    url,
  }) => {
    return (
      <Fragment>
        <Card style={{ width: "18rem" }}>
          <Card.Img
            style={{ border: borderColor }}
            variant="top"
            src={imageUrl}
            alt="service-icon"
          />
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>
              {description}
              <br />
              <ReadMore url={url} />
            </Card.Text>
          </Card.Body>
        </Card>
      </Fragment>
    );
  };
  return (
    <div className="">
      <div className="home-image-container">
        <div className="home-description">
          <div className="welcome-message">
            <div>
              <h2>Tracy Acupuncture and Herbs</h2>
              <h5>
                <i>Rockville, Maryland's #1 Acupuncture and Herbal Clinic</i>
              </h5>
            </div>
          </div>
          <div className="biography-container">
            <p>
              Slip away into the world of acupuncture with the help of Dr. Tracy
              Gao. The Tracy Acupunctural and Herbal Clinic is dedicated to
              improving lives through traditional Chinese practices, such as
              acupuncture, herbal medicine, and heat therapy. Experience freedom
              of the mind, body, and soul using ancient techniques that have
              been perfected under the scrutiny of modern science.
            </p>
            <img src={selfie} alt="selfie"></img>
          </div>
        </div>
      </div>
      <div className="more-info-home">
        <img
          alt="background-banner"
          src="https://images.unsplash.com/photo-1483137140003-ae073b395549?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80"
        ></img>
        <div>
          <h5>
            Whatever your reason is for seeking treatment, <b>Dr. Tracy Gao</b>{" "}
            has a solution for you:
          </h5>
          <ul>
            {reasonsForTreatment.map((x, index) => {
              return (
                <li key={index + "-key"}>
                  <i>{x}</i>
                </li>
              );
            })}
          </ul>
          <p style={{ lineHeight: "2em" }}>
            Dr. Tracy Gao is a licensed professional with years of experience in
            all the services listed below. More information is provided about
            both Dr. Tracy Gao and the services she offers on the other pages.
          </p>
        </div>
      </div>
      <div className="services-big-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <small style={{ padding: "1em" }}></small>
        </div>
        <div className="d-flex home-card-container">
          <ServicesComponent
            borderColor="2px #ff3333 solid"
            imageUrl="https://cdn-icons-png.flaticon.com/512/1328/1328654.png"
            title="Acupuncture"
            description="Every fighter needs a break. Free yourself from life's
            pressures."
            url="/services#Acupuncture"
          />
          <ServicesComponent
            borderColor="2px #ffcc66 solid"
            imageUrl="https://cdn-icons-png.flaticon.com/512/4857/4857046.png"
            title="Acupressure"
            description="Free the life energy that flows within you."
            url="/services#Acupressure"
          />
          <ServicesComponent
            borderColor="2px #2eb82e solid"
            imageUrl="https://cdn-icons-png.flaticon.com/512/3209/3209069.png"
            title="Herbal Medicine"
            description="Eastern medicine heals both the body and the spirit."
            url="/services#TraditionalChineseMedicine"
          />
        </div>
        <div className="d-flex home-card-container">
          <ServicesComponent
            borderColor="2px #33ccff solid"
            imageUrl="https://cdn-icons-png.flaticon.com/512/2530/2530999.png"
            title="Cupping Therapy"
            description="Encourage the blood within your body to flow freely."
            url="/services#CuppingTherapy"
          />
          <ServicesComponent
            borderColor="2px #29293d solid"
            imageUrl="https://cdn-icons-png.flaticon.com/512/4888/4888003.png"
            title="Gua Sha"
            description="Increase your blood flow and reduce any chronic pains you feel."
            url="services#GuaSha"
          />
          <ServicesComponent
            borderColor="2px #ff9900 solid"
            imageUrl="https://cdn-icons-png.flaticon.com/512/785/785116.png"
            title="Moxibustion"
            description="Strengthen and fortify your organs and immune system."
            url="/services#Moxibustion"
          />
        </div>
      </div>
      <p></p>
    </div>
  );
};
