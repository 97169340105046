import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export const ReadMore = ({ url }) => {
  return (
    <Link
      className="read-more-link"
      style={{
        textEmphasis: "none",
        color: "rgb(60 103 46)",
      }}
      to={url}
    >
      Read More <FontAwesomeIcon icon={faArrowRight} />
    </Link>
  );
};
