import React, { Component } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from "react-router-dom";

export class HeaderComponent extends Component {
  render() {
    return (
      <div>
        <div className="nav-bar-custom">
          <NavLink exact to="/">
            Home
          </NavLink>
          <NavDropdown
            title="About"
            style={{ marginLeft: -10, marginRight: -10 }}
          >
            <NavDropdown.Item>
              <NavLink exact to="/about">
                Dr. Qin (Tracy) Gao
              </NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <NavLink exact to="/photos">
                Photos
              </NavLink>
            </NavDropdown.Item>
            {/* <NavDropdown.Item>
              <NavLink exact to="/Response">
                COVID-19 Response
              </NavLink>
            </NavDropdown.Item> */}
          </NavDropdown>
          <NavLink exact to="/services">
            Services
          </NavLink>
          <NavLink exact to="/new">
            New Patients
          </NavLink>
        </div>
        <div
          className="nav-bar-custom-covid"
          style={{ fontSize: 17, height: "45px" }}
        >
          <b>Call Today!</b>&nbsp;(914)-659-0083
        </div>
      </div>
    );
  }
}
