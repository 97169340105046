import React from "react";
import { HashLink as Link } from "react-router-hash-link";

export const NewPatient = () => {
  return (
    <div>
      <div className="new-patient-title">
        <h1>New Patient Information</h1>
        <p>
          Starting is as simple as giving Dr. Tracy Gao a quick call and
          following the steps outlined below:
        </p>
      </div>
      <div className="new-patient">
        <div>
          <span className="new-patient-image">
            <img
              src="https://cdn-icons-png.flaticon.com/512/3522/3522781.png"
              alt="one"
            />
          </span>
          <span className="new-patient-body one">
            <h5>Schedule a Consultation</h5>
            <p>
              Call or email to schedule the initial consultation. The necessary
              forms will be provided at the session, and all questions will be
              answered then. During this session, Dr. Tracy Gao will determine
              which{" "}
              <Link
                to="/Services"
                style={{ color: "inherit", textDecoration: "underline" }}
              >
                services
              </Link>{" "}
              to use for future sessions.
            </p>
          </span>
        </div>
        <div>
          <span className="new-patient-image">
            <img
              src="https://cdn-icons-png.flaticon.com/512/3522/3522801.png"
              alt="two"
            />
          </span>
          <span className="new-patient-body two">
            <h5>Receive Herbal Medicine</h5>
            <p>
              As both a licensed Herbalist and Acupuncturist, Dr. Tracy Gao can
              and will find the perfect herbal treatments for each patient. If
              you choose to receive herbal treatments, Dr. Tracy Gao will
              perform an additional diagnosis for the best treatment plan.
            </p>
          </span>
        </div>
        <div>
          <span className="new-patient-image">
            <img
              src="https://cdn-icons-png.flaticon.com/512/3522/3522827.png"
              alt="three"
            />
          </span>
          <span className="new-patient-body three">
            <h5>Keep Following the Plan!</h5>
            <p>
              After the initial consultation, Dr. Tracy Gao will have a
              personalized plan for you. For maximum benefits, be sure to go to
              the recommended number of sessions and follow the herbal plan.
            </p>
          </span>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: 20, paddingBottom: 20 }}>
        <small>
          <i>
            For prices, insurance info, or eligible discounts, be sure to give
            Dr. Tracy Gao a quick call.
          </i>
        </small>
      </div>
    </div>
  );
};
