import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEnvelope,
  faHome,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { HashLink as Link } from "react-router-hash-link";

export const FooterComponent = () => {
  return (
    <div>
      <div className="address-section">
        <ul className="bottom-links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Dr. Gao</Link>
          </li>
          <li>
            <Link to="/new">New Patient Info</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/photos">Photos</Link>
          </li>
        </ul>
        <ul id="address">
          <div style={{ paddingTop: 10 }}>
            <FontAwesomeIcon icon={faHome} />
            <li style={{ marginTop: -25, marginLeft: 10 }}>
              <li style={{ paddingLeft: 7 }}>Rockville, Maryland 20851</li>
            </li>
          </div>
          <li style={{ paddingTop: 9 }}>
            <FontAwesomeIcon icon={faPhone} />
            &nbsp;&nbsp;914.659.0083
          </li>
          <li style={{ paddingTop: 9 }}>
            <FontAwesomeIcon icon={faEnvelope} />
            &nbsp;&nbsp; tracygaoacupuncture@gmail.com
          </li>
          <li style={{ paddingTop: 9 }}>
            <FontAwesomeIcon icon={faClock} />
            &nbsp; Mon - Sat: 9am - 7pm (appointment only)
          </li>
        </ul>
      </div>
      <div
        className="copyright-section"
        style={{ textAlign: "center", paddingBottom: 10 }}
      >
        <small>
          &#169; {new Date().getFullYear()} Tracy Gao Acupuncture. All Rights
          Reserved.
        </small>
      </div>
    </div>
  );
};
