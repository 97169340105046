import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Office1 from "./Images/Office1.webp";
import Office2 from "./Images/Office2.webp";
import Office3 from "./Images/Office3.webp";
import Office4 from "./Images/Office4.webp";
import Office5 from "./Images/Office5.webp";

export const Photos = () => {
  const photoArray = [Office1, Office2, Office3, Office4, Office5];

  const PhotoContainer = ({ photo }) => {
    return (
      <Carousel>
        {photo.map((x) => {
          return (
            <Carousel.Item>
              <img className="d-block w-75 mx-auto" src={x} alt="clinic-item" />
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  };

  return (
    <div className="photo-container">
      <PhotoContainer photo={photoArray} />
    </div>
  );
};
