import React from "react";
import Selfie2 from "./Images/Selfie2.webp";

export const About = () => {
  return (
    <div className="about-me-container">
      <fadeIn>
        <div className="left-side-about-me">
          <img src={Selfie2} alt="selfie" />
        </div>
      </fadeIn>
      <div className="about-me-text">
        <h3>About Dr. Qin (Tracy) Gao</h3>
        <p>
          Dr. Qin (Tracy) Gao's number one focus is to help patients through the
          power of acupuncture and Traditional Chinese Medicine. Tracy is a
          Licensed Acupuncturist in the State of New York and has received the
          Diplomate of Acupuncture and the Diplomate of Oriental Medicine from
          the National Certification Committee on Acupuncture and Oriental
          Medicine (NCCAOM). In addition, she went through the New York College
          of Traditional Chinese Medicine to receive a formal education in this
          field.
        </p>
        <p>
          Dr. Tracy Gao specializes in Acupuncture, Traditional Chinese
          Medicine, acupressure, moxibustion, cupping therapy, and gua sha. She
          has not only opened up her own clinic, but in the past has also
          studied with and worked with professors and other practicioners from
          the New York College of Traditional Chinese Medicine and has attended
          variousseminars and conferences across the world.
        </p>
        <p>
          Not only did Dr. Tracy Gao study thoroughly in the past, she continues
          to push herself to learn more from the top experts and through her own
          studies. She constantly travels to various regions in China just to
          update herself with the latest teachings in acupuncture, herbs,
          applied channel theory, trigger points, and many more.
        </p>
        <p>
          Lastly, Dr. Tracy Gao holds a very comprehensive view of medicine. Not
          only has she extensively studied Traditional Chinese Medicine, before
          her time as an acupuncturist, she worked as a pharmacist for over ten
          years. Dr. Tracy Gao will never deny the usefulness of Western
          Medicine, as she seen first hand its effectiveness. However, because
          Dr. Gao has thoroughly studied both Western and Eastern Medicine, she
          knows that each offers its own benefits and healings, and wishes to
          bestow those benefits to her patients.
        </p>
      </div>
    </div>
  );
};
