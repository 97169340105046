import React, { useEffect } from "react";
export const Services = () => {
  const scrollToLocation = () => {
    const { hash } = window.location;
    if (hash !== "") {
      let retries = 0;
      const id = hash.replace("#", "");
      const scroll = () => {
        retries += 0;
        if (retries > 50) return;
        const element = document.getElementById(id);
        if (element) {
          setTimeout(
            () =>
              element.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
              }),
            0
          );
        } else {
          setTimeout(scroll, 100);
        }
      };
      scroll();
    }
  };

  useEffect(() => {
    scrollToLocation();
  }, []);
  const ServicesComponent = ({ title, paragraph, links, imageUrl }) => {
    return (
      <div id={title.replace(/\s/g, "")} className="services-container">
        <div className="services-title-container">
          <h1>{title}</h1>
          <img src={imageUrl} alt="sevice-icon" />
        </div>
        <div className="services-body-container">
          <p>{paragraph}</p>
          <div className="list-div">
            <ul>
              {" "}
              {links.map((x) => {
                return (
                  <li>
                    <a target="_blank" href={x.url} rel="noopener noreferrer">
                      {x.description}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="services-page">
      <div className="">
        <ServicesComponent
          title="Acupuncture"
          imageUrl="https://cdn-icons-png.flaticon.com/512/1328/1328654.png"
          paragraph="Acupuncture is a complementary medical practice that entails stimulating certain points on the body, most often with a needle penetrating the skin, to alleviate pain or to help treat various health conditions.
          The practice dates back to ancient China but has been perfected over the millenniums into a worldwide treatment. The benefits are endless - some include pain management, treatment for digestive issues,
          emotional stability, and fertility treatment. Feel free to call or email anytime for more information, or click on the links below!"
          links={[
            {
              url: "https://www.alignedmodernhealth.com/top-10-benefits-acupuncture-workplace-stress-pain/",
              description: "Top Ten Benefits of Acupuncture",
            },
            {
              url: "https://www.webmd.com/pain-management/features/acupuncture-pain-killer#1",
              description: "Scientifically Proven Benefits of Acupuncture",
            },
          ]}
        />
        <ServicesComponent
          title="Acupressure"
          imageUrl="https://cdn-icons-png.flaticon.com/512/4857/4857046.png"
          paragraph='Similar to acupuncture, acupressure focuses on the life energy which flows through the meridians of the body. The key difference is that instead of a fine needle,
          physical pressure is applied (usually using a hand or an elbow) to specific points of the body. Using acupressure, a skilled practitioner can set the body up for more
          "precise" treatments, but also in itself has benefits such as stress and tension relief, sleep improvement, and muscle relaxation. Call, email, or read the links below for more information!'
          links={[
            {
              url: "https://www.verywellhealth.com/the-benefits-of-acupressure-88702",
              description: "Benefits and Uses of Acupressure",
            },
            {
              url: "https://www.rogelcancercenter.org/support/symptoms-and-side-effects/alternative-medicine/acupressure",
              description: "Why You Should Get Acupressure",
            },
          ]}
        />
        <ServicesComponent
          title="Traditional Chinese Medicine"
          imageUrl="https://cdn-icons-png.flaticon.com/512/3209/3209069.png"
          paragraph="Traditional Chinese Medicine (TCM) is based on thousands of years of studying the purpose, flow, and impact of energy (Chi) in the body. Using these medicines,
          the benefits are endless. The common theme between all TCMs is that they have stood the test of time in both ancient and modern day China and are still wildly popular even with
          modern Western Medicine. Dr. Tracy Gao is an expert regarding Chinese herbs and medicines - feel free to consult her anytime!"
          links={[
            {
              url: "https://www.sochealth.co.uk/sp/dr/top-5-health-benefits-traditional-chinese-medicine/",
              description: "Top Five Benefits of Traditional Chinese Medicine",
            },
            {
              url: "https://www.verywellhealth.com/traditional-chinese-medicine-what-you-need-to-know-88936",
              description: "Facts about Traditional Chinese Medicine",
            },
          ]}
        />
        <ServicesComponent
          title="Cupping Therapy"
          imageUrl="https://cdn-icons-png.flaticon.com/512/2530/2530999.png"
          paragraph="Cupping Therapy is the ancient art of focusing and squeezing specific parts of the body using small glass cups. It is a wildly popular practice in China intended to
          draw congested blood and energy to the surface of the skin. While effective in itself, it is often paired with acupuncture as a general treatment for the stresses from life."
          links={[
            {
              url: "https://www.pacificcollege.edu/news/blog/2014/09/20/many-benefits-chinese-cupping-1",
              description: "The Many benefits of Chinese Cupping",
            },
          ]}
        />
        <ServicesComponent
          title="Moxibustion"
          imageUrl="https://cdn-icons-png.flaticon.com/512/785/785116.png"
          paragraph='Moxibustion is the traditional Chinese medicine therapy of burning "moxa", or dried plant materials, on particular points on the body. Using these herbal plant materials,
          the primary purposes are to strengthen the immune system, reduce pain through the body, and invigorate the flow of energy in the body.'
          links={[
            {
              url: "https://www.takingcharge.csh.umn.edu/explore-healing-practices/moxibustion",
              description: "What is Moxibustion and what are its benefits?",
            },
          ]}
        />
        <ServicesComponent
          title="Gua Sha"
          imageUrl="https://cdn-icons-png.flaticon.com/512/4888/4888003.png"
          paragraph="Gua Sha offers a unique approach to relieving chronic pain. It involves gently scraping the skin with massage tools in order to promote circulation of blood and energy throughout the body."
          links={[
            {
              url: "https://www.healthline.com/health/gua-sha",
              description: "Some of the many benefits of Gua Sha",
            },
          ]}
        />
      </div>
    </div>
  );
};
